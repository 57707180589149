<template>
    <div class="t-popup">
        <div class="t-logo">
            <img src="@/assets/images/t-logo.png" alt="" />
        </div>

        <HeadAndSubTexts
            class="t-text"
            heading="THANK YOU!"
            subHeading2="Thank you for choosing Siloho."
        />

        <div class="t-btn">
            <router-link
                :to="{
                    name: 'ExecutionCard',
                    params: { id: $route.params.id },
                }"
            >
                <Button class="n-btn" outline name="Order progress" />
            </router-link>

            <Button class="p-btn" primary name="Back to Home" />
        </div>
    </div>
</template>

<script>
import HeadAndSubTexts from 'componentsv2/HeadAndSubTexts'
import Button from 'componentsv2/Button'

export default {
    name: 'ThankYouPage',
    components: {
        Button,
        HeadAndSubTexts,
    },
}
</script>

<style lang="scss" scoped>
@import './ThankYouPage.scss';
</style>
